
/* og off white - #F9F6EE */

body {
  background-color: #F9F6EE;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
