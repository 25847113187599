@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.App {
  text-align: center;
  font-family: 'Anton', sans-serif;
  text-transform: uppercase;
}

.Title {
  font-size: 550%;
  word-spacing: 65px;
  margin-bottom: -1%;
  color: #F9F6EE;
  background-color: black;
  /* letter-spacing: 1px; */
  /* width: 89%; */
}

.Nav {
  display: flex;
  justify-content: center;
  margin-top: 1.5%;
  margin-bottom: 3%;
  gap: 25px;
  font-size: 160%;
  background-color: #FAFAFA;
  /* background-color: #FFC0CB; (PINK)*/
  letter-spacing: 4px;
  padding: 3%;
  height: 10px;
}

.Errors {
  color: red;
  padding-left: 0;
  letter-spacing: 1px;
}

.Auth {
  margin-top: 100px;
}

.Profile {
  font-size: larger;
  letter-spacing: 1.5px;
}

#stats {
  background-color: black;
  color: #F9F6EE;
}

label, input {
  padding: 5px;
}


.Note {
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 50px;
  margin-top: 20px;
  padding: 10px;
  letter-spacing: 1px;
  text-align: left;
}


.Lists {
  margin: auto;
  width: 50%;
  padding: 8px;
}

.Album {
  padding: 15px;
  word-spacing: 2px;
}

#album-card {
  background-color: white;
  color: black;
  border-radius: 8px;
  letter-spacing: 1px;
}

#song {
  background-color: white;
  color: black;
  border-radius: 8px;
  letter-spacing: 1px;
  text-align: left;
  padding-left: 20px;
  font-size: small;
  padding-bottom: 20px;
}

.credits {
  word-spacing: 20px;
  text-align: center;
  font-size:medium
}

.No-Content {
  margin-top: 100px;
  font-size: 300%;
}

.button {
  transition-duration: 0.2s;
  background-color: lightgray;
  color: #F9F6EE;
  border-radius: 5px;
  border: none;
  letter-spacing: 2px;
  word-spacing: 5px;
  font-family: 'Anton', sans-serif;
  font-size: 120%;
  margin: 10px;
  width: 40%;
  margin-bottom: 10px;
}

.button:hover {
  background-color: skyblue;
}

#card-button {
  font-size: small;
  width: 10%;
}

.new-item {
  display: block;
  margin: auto;
  text-align: center;
}